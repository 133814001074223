<template>
  <v-dialog v-model="dialogCreateShow" max-width="700px">
    <v-card class="pa-8 pa-sm-16">
      <v-btn class="button-close ma-4" icon @click="dialogCreateShow = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <h2 class="text-center">Данные корпоративного клиента</h2>
      <form @submit.prevent="createClientConfirm" class="mt-6">
        <div class="container">

          <div class="row">
            <div class="col-md-6">
              <v-btn class="w-100" color="primary" rounded :outlined="withManager" @click="withManager=false">Без менеджера</v-btn>
            </div>
            <div class="col-md-6">
              <v-btn class="w-100" color="primary" rounded :outlined="!withManager" @click="withManager=true">C менеджером</v-btn>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <v-text-field label="Фамилия *" :value="editedItem.last_name"
                @input="($v.editedItem.last_name.$model = upperFirst($event)), onInput('last_name')"
                :error-messages="lastnameErrors" outlined dense
              />
            </div>
            <div class="col-md-6">
              <v-text-field label="Имя *" :value="editedItem.first_name"
                @input="($v.editedItem.first_name.$model = upperFirst($event)), onInput('first_name')"
                :error-messages="firstnameErrors" outlined dense
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <v-text-field label="Адрес эл. почты *" v-model.trim="editedItem.email"
                @input="clearError" @blur="$v.editedItem.email.$touch()"
                :error-messages="emailErrors" outlined dense />
            </div>
            <div class="col-md-6">
                <v-text-field label="Компания" type="text" v-model="editedItem.company" outlined dense />
            </div>
          </div>
          <div class="row">
              <div class="col-md-6">
                <v-select  v-model="editedItem.partnerId"
                  :items="partners" label="Партнер"
                  :item-text="(item) => item.first_name || item.last_name ? item.first_name + ' ' + item.last_name : 'имя не задано'"
                  item-value="id" outlined dense
                  :disabled="!$store.getters['auth/isAllowed']('change_partner_of_corpclient')"
                  :error-messages="partnerError"
                />
              </div>
              <div class="col-md-6">
                <v-select v-model="editedItem.promocode" v-show="withManager"
                  :items="codes" label="Промокод"
                  item-text="title" item-value="id" outlined dense
                />
              </div>
          </div>
          <!-- <div v-for="(err, ind) in errors.create" :key="ind" class="error--text">
            <template v-if="!editedItem.hasOwnProperty(err[0])">
              <div v-for="(e, i) in err[1]" :key="i">{{ e }}</div>
            </template>
          </div> -->
        </div>
        <div class="row mt-3">
          <div class="col-md-6 mx-auto">
            <v-btn type="submit" class="w-100" color="primary" :loading="loading.create" :disabled="loading.create"> Сохранить </v-btn>
          </div>
        </div>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
/*eslint-disable*/
import api from '@/common/api';
import { mapActions, mapGetters } from 'vuex';
import { email, maxLength, required, minValue } from 'vuelidate/lib/validators';
import { cloneDeep, upperFirst } from 'lodash';
import { firstnameValidation, lastnameValidation } from '@/common/validationHelpers';
import { EMAIL, FIRST_NAME, LAST_NAME } from '@/common/constants/validations';
import { MESSAGES } from '@/common/constants/errorMessages';

export default {
  name: 'ClientCreateModal2',
  validations: {
    editedItem: {
      last_name: { required, lastnameValidation, maxLength: maxLength(LAST_NAME.MAX_LENGTH) },
      first_name: { required, firstnameValidation, maxLength: maxLength(FIRST_NAME.MAX_LENGTH) },
      email: { required, email, maxLength: maxLength(EMAIL.MAX_LENGTH) },
      partnerId: { required, minValue: minValue(1) },
    }
  },
  data: () => ({
    editedItem: {
      last_name: '',
      first_name: '',
      partnerId: 0,
      company: '',
      email: '',
      promocode: ''
    },
    defaultItem: {
      last_name: '',
      first_name: '',
      partnerId: 0,
      email: '',
      company: '',
      promocode: ''
    },
    withManager: false,
    codes: [],
  }),
  watch: {
    async dialogCreateShow(val) {
      if (val) {
        if (!this.partners?.length && !this.loadingPartners.load) {
          await this.loadPartners();
        }
        console.log(this.user.user_type, this.partners, )
        if (this.user.user_type === 'Partner' && this.partners.length) {
          this.editedItem.partnerId = this.partners.length ? this.partners[0].id : 0
        }

        if (!this.$store.getters['auth/isAllowed']('change_partner_of_corpclient') && this.editedItem.partnerId===0)
          this.editedItem.partnerId = this.partners.length ? this.partners[0].id : 0

        // if (!this.codes?.length) {
        //   await this.loadCodes({ user_type: this.user.user_type, id: this.user.id });
        // }
        // console.log('this.defaultRef', this.defaultRef);
      }
    },
    'editedItem.partnerId': function () {
      this.loadCodes(this.editedItem.partnerId);
    }
  },
  computed: {
    ...mapGetters({
      loading: 'users/clients/loading',
      errors: 'users/clients/errors',
      editedClient: 'users/clients/editedClient',
      partners: 'users/partners/partners',
      loadingPartners: 'users/partners/loading',
      defaultRef: 'profile/refs/defaultRef',
      user: 'auth/user',
    }),
    dialogCreateShow: {
      get() {
        return this.$store.getters['users/clients/dialogCreateShow'];
      },
      set(val) {
        if (!val) {
          this.editedItem = cloneDeep(this.defaultItem);
          this.$v.$reset();
        }
        this.setDialogCreateShow(val);
      }
    },
    dialogDeleteShow: {
      get() {
        return this.$store.getters['users/clients/dialogDeleteShow'];
      },
      set(val) {
        this.setDialogDeleteShow(val);
      }
    },
    lastnameErrors() {
      const errors = [];
      if (!this.$v.editedItem.last_name.$dirty) return errors;
      if (!this.$v.editedItem.last_name.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.editedItem.last_name.lastnameValidation) errors.push(MESSAGES.INCORRECT_LASTNAME);
      if (!this.$v.editedItem.last_name.maxLength) errors.push(MESSAGES.MAX_LENGTH(LAST_NAME.MAX_LENGTH));

      return this.errors.create.find(err => err[0] === 'last_name')?.[1] ?? errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.editedItem.email.$dirty) return errors;
      if (!this.$v.editedItem.email.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.editedItem.email.email) errors.push(MESSAGES.INCORRECT_EMAIL);
      if (!this.$v.editedItem.email.maxLength) errors.push(MESSAGES.MAX_LENGTH(EMAIL.MAX_LENGTH));

      return this.errors.create.find((err) => err[0] === 'email')?.[1] ?? errors;
    },
    firstnameErrors() {
      const errors = [];
      if (!this.$v.editedItem.first_name.$dirty) return errors;
      if (!this.$v.editedItem.first_name.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.editedItem.first_name.firstnameValidation) errors.push(MESSAGES.INCORRECT_FIRSTNAME);
      if (!this.$v.editedItem.first_name.maxLength) errors.push(MESSAGES.MAX_LENGTH(FIRST_NAME.MAX_LENGTH));

      return this.errors.create.find(err => err[0] === 'first_name')?.[1] ?? errors;
    },
    partnerError() {
      const errors = [];
      if (!this.$v.editedItem.partnerId.$dirty) return errors;
      if (!this.$v.editedItem.partnerId.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.editedItem.partnerId.minValue) errors.push(MESSAGES.REQUIRED);

      return this.errors.create.find(err => err[0] === 'supervisor')?.[1] ?? errors;
    },
  },
  methods: {
    ...mapActions({
      clearError: 'users/clients/clearError',
      setDialogCreateShow: 'users/clients/setDialogCreateShow',
      setDialogDeleteShow: 'users/clients/setDialogDeleteShow',
      createClient: 'users/clients/createClient',
      loadPartners: 'users/partners/loadPartners',
    }),
    onInput() {
      if (this.errors.create.length) this.clearError();
    },
    async loadCodes() {
      try {
        this.editedItem.promocode = 0;
        if (!this.editedItem.partnerId) {
          this.codes = [];
          return;
        }
        this.codes = await api.post(`/users/partners/${this.editedItem.partnerId}/get-promocodes/`);
        this.codes = this.codes.filter(el => el.status !== 'expired')
        this.codes.push({ title: "-", id: 0 });

        this.editedItem.promocode = this.codes.find(el => el.is_default)
        if (!this.editedItem.promocode && this.codes.length)
          this.editedItem.promocode = this.codes[0];
        if (this.editedItem.promocode)
          this.editedItem.promocode = this.editedItem.promocode.id
        // this.editedItem.promocode = this.user.user_type === 'Partner' && this.defaultRef ? this.defaultRef.id : 0;
      } catch (err) {
        this.$toast.error("Ошибка при загрузке промокодов", '', { position: 'topRight' });
      }
    },

    createClientConfirm() {
      if (this.loading.create) return;
      this.$v.$touch();
      if (this.editedItem && !this.$v.$invalid) {
        const supervisor = this.user.user_type === 'Partner' ? this.user.id : this.editedItem.partnerId;
        const form = {
          ...this.editedItem,
          supervisor,
          withManager: this.withManager
        };
        this.createClient(form).then(() => {
          this.dialogCreateShow = false;
        }).catch((err) => {
          console.log(err);
          this.$toast.error("Ошибка при создании корпоративного клиента", '', { position: 'topRight' });

        });
      }
    },
    upperFirst
  }
};
</script>
