<template>
  <v-data-table
    :headers="headers"
    :items="clients"
    class="px-5"
    :loading="loading.load"
    loading-text="Загрузка... Пожалуйста подождите"
    :mobile-breakpoint="300"
    :height="tableHeight"
    fixed-header
    hide-default-footer
    :custom-sort="customSort"
    :items-per-page="-1"
  >
    <!-- <template v-slot:top>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <ClientCreateModal />
      </v-toolbar>
    </template> -->

    <!--  Cells  -->
    <!-- <template v-slot:item.check="{ item }">
        <div>
          <v-checkbox :input-value="selected.some((id) => id === item.id)"
                      @change="setSelected(item.id)" hide-details class="mt-auto" />
        </div>
      </template> -->

    <template v-slot:item.fullname="{ item }">
      <div class="d-flex justify-space-between align-center">
        <router-link
          v-if="$store.getters['auth/isAllowed']('view_corpclient')"
          class="text-primary mr-2"
          :class="{ 'grey--text lighten-2': !item.full_name && !item.first_name }"
          :to="{ name: 'clients-id', params: { id: item.id } }"
        >
          {{ getFullName(item) }}
        </router-link>
        <span v-else> {{ getFullName(item) }} </span>
      </div>
    </template>

    <template v-slot:item.manager="{ item }">
      <span> {{ item.supervisor ? item.supervisor.last_name + ' ' + item.supervisor.first_name : '' }} </span>
    </template>

    <template v-slot:no-data> Корпоративных клиентов не найдено. </template>

    <template v-slot:footer="{ props }">
      <v-data-footer
        :pagination="props.pagination"
        :options="props.options"
        items-per-page-all-text="Все"
        items-per-page-text="Клиентов на странице:"
        :items-per-page-options="[-1]"
        :page-text="`Всего клиентов: ${props.pagination.itemsLength}. Страница ${props.options.page} из ${props.pagination.pageCount}`"
        show-current-page
        show-first-last-page
      />
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import ClientCreateModal from '@/components/clients/ClientCreateModal';

export default {
  name: 'ClientsTable',
  components: {
    // ClientCreateModal
  },
  data: () => ({
    tableHeight: 400,
    headers: [
      {
        text: 'Id', value: 'id',
      },
      {
        text: 'Фамилия и Имя',
        value: 'fullname'
        // width: '280px',
      },
      //   {
      //     text: 'Электронная почта',
      //     value: 'email'
      //     // width: '280px',
      //   },
      {
        text: 'Компания',
        value: 'company'
        // width: '280px',
      },
      // {
      //   text: 'Менеджер',
      //   value: 'manager',
      //   sortable: false
      // },
      {
        text: 'Баланс тестов', value: 'stock_balance', width: '150px',
      },
      // {
      //   text: '',
      //   value: 'actions',
      //   sortable: false,
      //   align: 'end',
      // },
    ]
  }),
  computed: {
    ...mapGetters({
      loading: 'users/clients/loading',
      clients: 'users/clients/clients',
      selected: 'users/clients/selected',
      count: 'users/clients/count',
      loadingSession: 'testing/session/loading'
    })
  },
  methods: {
    ...mapActions({
      setSelected: 'users/clients/setSelected',
      allSelected: 'users/clients/allSelected',
      clearSelected: 'users/clients/clearSelected',
      loadClients: 'users/clients/loadClients',
      deleteClientDialog: 'users/clients/deleteClientDialog'
    }),
    initialize() {
      this.loadClients();
    },
    onChangeAllCheck(bool) {
      if (bool) {
        this.allSelected();
      } else {
        this.clearSelected();
      }
    },
    getFullName(item) {
      return item.last_name || item.first_name ? `${item.last_name} ${item.first_name}` : 'Имя не задано';
    },
    customSort(items, [index], [isDesc]) {
      items.sort((a, b) => {
        if (index === 'fullname') {
          const aname = a.last_name || a.first_name ? `${a.last_name} ${a.first_name}` : 'Я';
          const bname = b.last_name || b.first_name ? `${b.last_name} ${b.first_name}` : 'Я';
          return !isDesc ? aname.localeCompare(bname) : bname.localeCompare(aname);
        }
        if (!isDesc) {
          return a[index] < b[index] ? -1 : 1;
        }
        return b[index] < a[index] ? -1 : 1;
      });
      return items;
    }
  },
  watch: {},
  created() {
    this.initialize();
  },
  mounted() {
    const height = document.documentElement.clientHeight - 270;
    this.tableHeight = Math.max(height, this.tableHeight);
  }
};
</script>
  <style lang="scss">
.v-list.v-sheet {
  padding: 0 !important;
}

.v-list-item__content {
  padding: 0 !important;
}

.v-list-item__action {
  margin-top: 0;
  margin-bottom: 0;
}

.v-select__selections {
  flex-wrap: nowrap;
}

.other-width-col {
  width: 13rem;
}
</style>
