<template>
  <div class="page">
    <div class="row">
      <div class="col-10 d-flex align-center">
        <h1 class="font-lg">Корпоративные клиенты</h1>
      </div>
      <div class="col-2 d-flex align-center" _v-if="$store.getters['auth/isAllowed']('add_corpclient')">
        <v-btn class="ml-auto d-sm-none" color="primary" small fab @click="setDialogCreateShow(true)">
          <v-icon small>mdi-account-plus</v-icon>
        </v-btn>
        <v-btn class="ml-auto d-none d-sm-block" color="primary" fab @click="setDialogCreateShow(true)">
          <v-icon small>mdi-account-plus</v-icon>
        </v-btn>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <ClientsTable />
      </div>
    </div>

    <ClientCreateModal />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { COLORS } from '@/common/constants/colors';
import ClientsTable from '@/components/clients/ClientsTable';
import ClientCreateModal from '@/components/clients/ClientCreateModal';

export default {
  name: 'Clients',
  metaInfo: {
    title: 'Менеджеры'
  },
  components: {
    ClientCreateModal,
    ClientsTable
  },
  data: () => ({
    colors: COLORS,
    sheet: false,
    remainsOfTests: false,
    usedTotal: true
  }),
  computed: {
    ...mapGetters({
      selected: 'users/clients/selected'
    })
  },
  methods: {
    ...mapActions({
      setSelected: 'users/clients/setSelected',
      setDialogCreateShow: 'users/clients/setDialogCreateShow'
    })
  },
  beforeDestroy() {
    this.setSelected(null);
  }
};
</script>
